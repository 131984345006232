<template>
  <div>
    <van-nav-bar
      title="vip申请列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!--搜索框-->
    <van-cell-group>
      <van-field
        label="会员账号"
        v-model="phone"
        placeholder="会员账号"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        label="会员名称"
        v-model="nickName"
        placeholder="会员名称"
        :border="false"
        bind:change="onChange"
      />
      <van-field
        label="律所名称"
        v-model="lawName"
        placeholder="律所名称"
        :border="false"
        bind:change="onChange"
      />
      <!--申请人组件-->
      <opener @chooseApply="chooseApply" />
      <!--查询时间组件-->
      <queryDate @startResult="startResult" @endResult="endResult" />
      <van-button type="info" size="large" @click="onload">查询</van-button>
    </van-cell-group>
    <!--加载状态-->
    <div class="loading" v-if="loading">
      <van-loading color="#1989fa" :vertical="true">加载中...</van-loading>
    </div>
    <!--数据展示-->
    <div v-else>
      <el-table :data="tableData" max-height="700" border style="width: 100%">
        <el-table-column prop="account" label="会员账号" width="100" />
        <el-table-column prop="nickname" label="会员名称" width="100" />
        <el-table-column prop="lawFirmName" label="律所名称" width="100" />
        <el-table-column prop="memberStartTime" label="开通时间" width="100" />
        <el-table-column prop="memberEndTime" label="到期时间" width="100" />
        <el-table-column prop="rechargeMoney" label="充值金额" width="100" />
        <el-table-column fixed="right" label="操作" width="100" prop="status">
          <template slot-scope="scope" v-if="scope.row.status === ''">
            <el-button @click="approve(scope.row)" type="text" size="small"
              >审批</el-button
            >
            <el-button type="text" size="small" @click="refuse(scope.row)"
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="footer"
        small
        layout="prev, pager, next"
        :total="total"
        :page-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [], //初始化数据
      loading: true, //加载状态
      pageCount: 10, //展示条数
      currentPage: 1, //当前页
      nickName: "",
      phone: "",
      total: 0, //总条数
      applyUser: "",
      startTime: "", //开始时间
      endTime: "", //结束时间
      lawName: "" //律所名称
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //初始化
    onload() {
      this.loading = true;
      this.tableData = [];
      this.$axios
        .get(
          `${this.$base}/admin/member/apply/page?pageNum=${this.currentPage}&pageSize=10&regTime=&account=${this.phone}&nickname=${this.nickName}&applyUserName=${this.applyUser}&lawFirmName=${this.lawName}&beginTime=${this.startTime}&endTime=${this.endTime}`
        )
        .then((res) => {
          if (res.code === 200) {
            res.data.data.forEach((item) => {
              item.status = this.applyStatus(item.status);
              this.tableData.push(item);
            });
            this.total = res.data.total;
          }
        });
      this.loading = false;
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.tableData = [];
      this.currentPage = val;
      this.onload();
    },
    //选择认证情况
    chooseIsAuth(name) {
      this.isAuth = name;
    },
    //审批
    approve(value) {
      this.$axios
        .get(`${this.$base}/admin/member/apply/agree?id=${value.id}`)
        .then((res) => {
          if (res.code === 200) {
            this.$toast("审批成功");
            this.onload();
          }
        });
    },
    //驳回
    refuse(value) {
      this.$axios
        .get(`${this.$base}/admin/member/apply/reject?id=${value.id}`)
        .then((res) => {
          if (res.code === 200) {
            this.$toast("驳回成功");
            this.onload();
          }
        });
    },
    //审批状态
    applyStatus(val) {
      switch (val) {
        case "AGREE":
          return "同意";
        case "REFUSE":
          return "驳回";
        default:
          return "";
      }
    },
    //申请人
    chooseApply(val) {
      this.applyUser = val;
    },
    //开始时间
    startResult(val) {
      this.startTime = val;
    },
    //结束时间
    endResult(val) {
      this.endTime = val;
    }
  },
  mounted() {
    this.onload();
  }
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
